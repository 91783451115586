@charset "UTF-8";
@import url("./xs.scss");
@import url("./masthead.css");
@import url("./flags.css");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
body {
  padding: 0;
}

.content {
  position: relative;
  /* z-index: 30; */
}

#sign-in-overlay {
  display: none;
}

/****************** 
    SCOLLERS
********************/
/* Firefox */
.custom-scroller {
  scrollbar-width: thin;
  scrollbar-color: #c2c2c2;
}

/* Chrome, Edge, and Safari */
.custom-scroller::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.custom-scroller::-webkit-scrollbar-track {
  background: #efefef;
  border-radius: 3px;
}

.custom-scroller::-webkit-scrollbar-thumb {
  background-color: #c2c2c2;
  border-radius: 8px;
  border: 1px solid #e3f2fd;
}

.mobile-scroll-x {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 25%, rgb(255, 255, 255) 50%);
}

@layer base {
  html {
    font-family: "Source Sans 3";
  }
}
.menu-link::after {
  content: "";
}

.menu-link.active::after {
  width: 100%;
}

/*  Legal Page Styling for text area */
.legalSection :is(p) {
  margin-bottom: 10px;
}
.legalSection :is(h1) {
  font-size: 42px;
  font-style: normal;
  font-weight: 300;
  color: #434d68;
}
.legalSection :is(h2, h3) {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  color: #016560;
  margin-bottom: 5px;
}
.legalSection :is(ul) {
  list-style: disc;
  padding-left: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.bullet-icon li {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.bullet-icon li::before {
  content: url("https://corporate2-spring-uk.huguenots.co.uk/static/images/tick_hZEUq3M.svg");
  position: relative;
}

.singlepost-body :is(p) {
  margin-bottom: 10px;
}
.singlepost-body :is(h1) {
  color: #016560;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 24.384px;
  /* 87.086% */
  margin-bottom: 16px;
  margin-top: 30px;
}
.singlepost-body :is(h2) {
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 16px;
  margin-top: 30px;
}
.singlepost-body :is(h3) {
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-top: 30px;
  /* 150% */
}
.singlepost-body :is(q) {
  position: relative;
  display: block;
  color: #016560;
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
  line-height: 28.6px;
  /* 130% */
  margin: 42px;
  padding-left: 42px;
}
.singlepost-body :is(q)::before {
  color: #1a6360;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  position: absolute;
  left: 0;
}
.singlepost-body :is(ul) {
  margin: 10px 15px;
}
.singlepost-body :is(li) {
  position: relative;
}
.singlepost-body :is(li)::before {
  content: "●";
  position: absolute;
  left: -15px;
  color: #1a6360;
}
.singlepost-body .video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  margin: 1.8em 0;
}
.singlepost-body .video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.singleevent-body :is(p) {
  margin-bottom: 20px;
}

.misctext :is(p) {
  margin-bottom: 10px;
}
.misctext :is(h1) {
  color: #434d68;
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  line-height: 36px;
  margin-bottom: 18px;
}
.misctext :is(h1):not(:first-of-type) {
  margin-top: 42px;
}
.misctext :is(a) {
  color: #1a6360 !important;
}
.misctext :is(ul) {
  list-style-type: disc;
  padding-left: 1rem;
  margin-bottom: 10px;
}
.misctext.quote-name p {
  margin: 0;
  font-weight: 500;
}
.misctext.quote-name p:nth-last-of-type(1) {
  text-transform: capitalize;
  padding: 0;
  font-weight: 300;
}

.logo-area {
  transition: transform 0.01s 0.01s;
}

.scrolled .logo-area {
  transform: translateY(-999em);
}

.timeline {
  height: 200px;
}

.timeline::after {
  content: "";
  width: 100%;
  height: 2px;
  background-color: #434d68;
  display: block;
  position: absolute;
  top: 50%;
}

.intheloupe :is(q) {
  margin: 0 50px;
}
.intheloupe :is(q)::before {
  content: url("https://corporate2-spring-uk.huguenots.co.uk/static/images/quote-icon.svg");
  position: absolute;
  top: 30px;
  left: -30px;
}