.flag {
    background: transparent;
    width: 21px;
    height: 16px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border: 1px solid rgba(0, 0, 0, 0.051);
    background-image: url("https://corporate2-spring-uk.huguenots.co.uk/static/images/international.svg");
}

.flag.GB {
    background-image: url("https://corporate2-spring-uk.huguenots.co.uk/static/images/uk-flag.svg");
}

.flag.IS {
    background-image: url("https://corporate2-spring-uk.huguenots.co.uk/static/images/iceland-flag.svg");
}

.flag.NO {
    background-image: url("https://corporate2-spring-uk.huguenots.co.uk/static/images/norway-flag.svg");
}

.flag.DK {
    background-image: url("https://corporate2-spring-uk.huguenots.co.uk/static/images/denmark-flag.svg");
}

.flag.FI {
    background-image: url("https://corporate2-spring-uk.huguenots.co.uk/static/images/finland-flag.svg");
}

.flag.IE {
    background-image: url("https://corporate2-spring-uk.huguenots.co.uk/static/images/ireland-flag.svg");
}

.flag.SE {
    background-image: url("https://corporate2-spring-uk.huguenots.co.uk/static/images/sweden-flag.svg");
}

.flag.DE {
    background-image: url("https://corporate2-spring-uk.huguenots.co.uk/static/images/germany-flag.svg");
}

.flag.LU {
    background-image: url("https://corporate2-spring-uk.huguenots.co.uk/static/images/luxembourg-flag.svg");
}

.flag.CH {
    background-image: url("https://corporate2-spring-uk.huguenots.co.uk/static/images/switzerland-flag.svg");
}

.flag.US {
    background-image: url("https://corporate2-spring-uk.huguenots.co.uk/static/images/usa-flag.svg");
}

.flag.EU {
    background-image: url("https://corporate2-spring-uk.huguenots.co.uk/static/images/other-europe-flag.svg");
}
.flag.IT {
    background-image: url("https://corporate2-spring-uk.huguenots.co.uk/static/images/italy-flag.svg");
}
.flag.AU {
    background-image: url("https://corporate2-spring-uk.huguenots.co.uk/static/images/austria-flag.svg");
}
.flag.ES {
    background-image: url("https://corporate2-spring-uk.huguenots.co.uk/static/images/spain-flag.svg");
}
.flag.GR {
    background-image: url("https://corporate2-spring-uk.huguenots.co.uk/static/images/greece-flag.svg");
}
.flag.CY {
    background-image: url("https://corporate2-spring-uk.huguenots.co.uk/static/images/cyprus-flag.svg");
}
