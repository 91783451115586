@media screen and (max-width: 374px) {
    .xs {
        &-h- {
            &135 {
                height: 135px;
            }
        }

        &-w- {
            &full {
                width: 100%;
            }
            &min- {
                &200 {
                    min-width: 200px;
                }
            }

            &135 {
                width: 135px;
            }
            &200 {
                width: 200px;
            }
        }

        &-max-h- {
            &220 {
                max-height: 220px;
            }
        }

        &-gap- {
            &y- {
                &4 {
                    gap: 1rem;
                }
            }
        }
    }
}
